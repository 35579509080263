import { Upload } from '@/components/icons';
import { ActionButton } from '@/types/edit-action.ts';
import { useFileMutation } from '@/tanstack';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { DialogClose, DialogFooter } from '@/components/ui/dialog.tsx';
import { Label } from '@/components/ui/label.tsx';
import { Input } from '@/components/ui/input.tsx';
import { useStore } from '@/store';
import { useLazyQuery } from '@apollo/client';
import { CompanyConfigDocument } from '@@graphql';
import { useToast } from '@/components/ui/use-toast';
import { ACTION_TYPE_TITLE } from '@/constants';

interface UploadFileProps {
  toFolderId: string;
  refetchFolder?: () => Promise<void>;
  openDialog: (title: string, content: React.ReactNode) => void;
  closeDialog: () => void;
}

interface UploadFormProps extends Omit<UploadFileProps, 'openDialog'> {
  setCompanyConfig: any;
  companyUsedSizeQuery: any;
}

const MAX_FILE_SIZE = 50 * 1024 * 1024;
const UploadForm = ({
  toFolderId,
  refetchFolder,
  closeDialog,
  companyUsedSizeQuery,
  setCompanyConfig,
}: UploadFormProps) => {
  const { toast } = useToast();
  const { mutate } = useFileMutation({
    method: 'POST',
    path: `/api/upload/${toFolderId}`,
    contentType: 'multipart/form-data',
  });

  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as File[] | null;
    setError(null);
    if (files) {
      Array.from(files).forEach(file => {
        if (file.size > MAX_FILE_SIZE) {
          setError('檔案大小超過 50MB 限制，請選擇較小的檔案。');
          setSelectedFiles(null);
        } else {
          setSelectedFiles(prevFiles => (prevFiles ? [...prevFiles, file] : [file]));
        }
      });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedFiles) return;

    setError(null);
    setIsUploading(true);

    const handleOnError = (fileName: string) => (_error: Error) => {
      toast({
        title: `${fileName} 上傳失敗`,
        duration: 1500,
      });
    };

    const handleOnSettled = () => {
      setIsUploading(false);
      setTimeout(() => {
        closeDialog();
      }, 1000);
    };

    selectedFiles.forEach(file => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileId', toFolderId);
      toast({
        title: '檔案上傳中...',
        duration: 1500,
      });
      try {
        mutate(formData, {
          onSuccess: async () => {
            setIsUploading(false);
            toast({
              title: '檔案上傳成功',
              duration: 1500,
            });
            if (refetchFolder) await refetchFolder();
            const { data } = await companyUsedSizeQuery({
              fetchPolicy: 'network-only',
            });
            if (data?.company) {
              setCompanyConfig({ ...data.company });
            }
          },
          onError: handleOnError(file.name),
          onSettled: handleOnSettled,
        });
      } catch (e) {
        setError((e as Error)?.message || '上傳失敗');
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="items-center text-neutral-500 mt-1 text-xs">
        檔案上限為 50 MB，OCR 上限為 20 頁 20 MB
      </p>
      <div className="grid gap-4 py-4">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="file" className="text-right font-normal">
            選擇檔案
          </Label>
          <Input
            id="file"
            type="file"
            aria-label="選擇檔案"
            multiple={true}
            onChange={handleFileSelect}
            className="col-span-3"
            disabled={isUploading}
          />
        </div>
        {/*)}*/}
        {error && <div className="col-span-4 text-red-500">{error}</div>}
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="outline" type="button">
            取消
          </Button>
        </DialogClose>
        <Button type="submit" disabled={!selectedFiles?.length || isUploading}>
          {isUploading ? '上傳中...' : '上傳'}
        </Button>
      </DialogFooter>
    </form>
  );
};
export const UploadAction = (props: UploadFileProps): ActionButton => {
  const setCompanyConfig = useStore.companyConfig(state => state.setConfig);
  const [companyUsedSizeQuery] = useLazyQuery(CompanyConfigDocument);

  return {
    title: ACTION_TYPE_TITLE.UPLOAD,
    icon: <Upload />,
    onClick: () => {
      props.openDialog(
        '上傳檔案',
        <UploadForm
          setCompanyConfig={setCompanyConfig}
          companyUsedSizeQuery={companyUsedSizeQuery}
          {...props}
        />,
      );
    },
  };
};
