import { FormEvent, useState, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Pencil } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import {
  DepartmentPromptDocument,
  UsersDocument,
  SetUsersToDepartmentKeywordDocument,
} from '@@graphql';
import { useQuery, useMutation } from '@apollo/client';

interface UpdateNotificationDialogProps {
  departmentPromptId: string;
  departmentId: string;
  keywords: string;
  refetch: () => void;
}

const UpdateNotificationDialog = ({
  departmentPromptId,
  departmentId,
  keywords,
  refetch,
}: UpdateNotificationDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const { toast } = useToast();

  useQuery(DepartmentPromptDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { departmentPromptId },
    skip: !departmentPromptId || !isOpen,
    onCompleted: data => {
      setSelectedUsers(
        data?.departmentPrompt?.users?.map(user => ({
          label: `${user.name} (${user.email})`,
          value: user.id,
        })) || [],
      );
    },
  });

  const { data: usersData } = useQuery(UsersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { departmentId, onlyDepartmentUsers: true },
    skip: !departmentId || !isOpen,
  });

  const userOptions = useMemo(
    () =>
      usersData?.users?.map(user => ({
        label: `${user.name} (${user.email})`,
        value: user.id,
      })) || [],
    [usersData],
  );

  const availableUserOptions = useMemo(
    () =>
      userOptions.filter(
        user => !selectedUsers.some(selectedUser => selectedUser.value === user.value),
      ),
    [userOptions, selectedUsers],
  );

  const [setUsersToDepartmentKeyword] = useMutation(
    SetUsersToDepartmentKeywordDocument,
    {
      onCompleted: () => {
        refetch();
        toast({ title: '儲存成功', duration: 1500 });
      },
      onError: () => {
        toast({ title: '儲存失敗', duration: 1500 });
      },
    },
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const userIds = selectedUsers.map(user => user.value);
      await setUsersToDepartmentKeyword({
        variables: { departmentPromptId, userIds },
      });
    },
    [selectedUsers, departmentPromptId, setUsersToDepartmentKeyword],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          className="gap-1 font-normal text-primary"
          variant="ghost"
          onClick={() => setIsOpen(true)}
          aria-label="編輯文件分類規則"
        >
          <Pencil size={20} strokeWidth={1.5} />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>編輯通知名單</DialogTitle>
            <DialogDescription className="text-xs">
              {`若文件符合關鍵字「${keywords}」，系統將發送郵件通知以下使用者`}
            </DialogDescription>
          </DialogHeader>
          <div className="w-full mt-4">
            <MultipleSelector
              className="min-h-[80px]"
              onChange={setSelectedUsers}
              value={selectedUsers}
              hideClearAllButton
              hidePlaceholderWhenSelected
              placeholder="請選擇通知名單"
              options={availableUserOptions}
              emptyIndicator={
                <p className="text-center text-lg leading-10 text-gray-600 dark:text-gray-400">
                  沒有使用者
                </p>
              }
            />
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateNotificationDialog;
